<template>
  <div class="page bg-primary-900 min-h-screen w-full pb-0">
    <div class="w-page my-0 mx-auto page-padding text-white mt-10">
      <h1 class="text-5xl font-title">{{ title }}</h1>
      <div class="separator w-40 h-2 bg-primary-500"></div>
      <div class="mt-8 mb-8" v-html="text"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TextPage',
  computed: {
    title() {
      switch (this.$route.meta.type) {
        case 'cgv':
          return 'Conditions Générales de Vente';
        case 'handicap':
          return 'Handicap';
        case 'accessibility':
          return 'Conditions d\'accessibilité';
        case 'cgu':
          return 'Conditions Générales d\'Utilisation';
        case 'legals':
          return 'Mentions légales';
        case 'privacy':
          return 'Politique de confidentialité';
        default:
          return 'Chargement...';
      }
    },
    text() {
      switch (this.$route.meta.type) {
        case 'cgv':
          return this.$store.state.globalModal.cgv.content;
        case 'handicap':
          return this.$store.state.globalModal.handicap.content;
        case 'accessibility':
          return this.$store.state.globalModal.accessibility.content;
        case 'cgu':
          return this.$store.state.globalModal.cgu.content;
        case 'legals':
          return this.$store.state.globalModal.legals.content;
        case 'privacy':
          return this.$store.state.globalModal.privacy.content;
        default:
          return '';
      }
    },
  },
};
</script>

<style scoped>

</style>
